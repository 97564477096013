import { Maybe } from '@property-folders/common/types/Utility';
import * as Y from 'yjs';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import {
  AgencySalesperson,
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  FormInstanceSigning, FormInstanceUploadFileItem,
  FormSigningState,
  ManifestType,
  MaterialisedPropertyData,
  SignedStates,
  SigningInitiator,
  SigningMarketingData,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType,
  SigningSessionOrderItem,
  TransactionMetaData
} from '@property-folders/contract';
import { FormUtil, getSigningOrderVersion, SigningOrderVersion } from '@property-folders/common/util/form';

import './SigningConfiguration.scss';
import { PartySessionCard } from '@property-folders/components/dragged-components/signing/PartySessionCard';
import { AuthApi } from '@property-folders/common/client-api/auth';
import {
  FileTrackDocRoot,
  FormInstance,
  LandType,
  PropertyRootKey
} from '@property-folders/contract/yjs-schema/property';
import {
  generateHeadlineFromMaterialisedData, getUploadAsV2, stripExtension,
  unblockSigningPartyCategory
} from '@property-folders/common/yjs-schema/property';
import { LinkBuilder, SeoFriendlySlugOptions } from '@property-folders/common/util/LinkBuilder';
import {
  fieldIsSigned,
  FormTypes,
  getCompletedFiles,
  getFirstOrderedParty,
  getInvolvedSigningParties,
  getPartyDetailPaths,
  PartyCategory,
  partyCategoryToLabelStrings
} from '@property-folders/common/yjs-schema/property/form';
import { Button, Card, Container, Row } from 'react-bootstrap';
import { formatTimestamp, getTimeString } from '@property-folders/common/util/formatting';
import './SigningProcess.scss';
import { WizardSidebarSubsetProps, WizardStepPage } from '../Wizard/WizardStepPage';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { HistoryModal } from '@property-folders/components/dragged-components/signing/HistoryModal';
import { useNavigate } from 'react-router-dom';
import {
  ServeToPurchaserSection
} from '@property-folders/components/dragged-components/signing/ServeToPurchaserSection';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { PartyGroup } from '../../hooks/useSigningNavProps';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { YjsDocContext } from '../../context/YjsDocContext';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { SetupNetStateWritingYjsDocContext } from '../../form-gen-util/yjsStore';
import { mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { getProxyEmail, getProxyWithOriginalName } from '@property-folders/common/util/dataExtract';
import { SigningSessionDistribution } from '../../display/form/SigningSessionDistribution';
import { customFieldMetas } from '@property-folders/contract/property/meta';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { Predicate } from '@property-folders/common/predicate';
import { VoidSigningModal } from '../../display/VoidSigningModal';
import { FormContext } from '../../context/FormContext';
import { usePdfWorker } from '../../hooks/usePdfWorker';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { downloadObjectUrl } from '../../react-util/download-object-url';
import { StringUtil } from '@property-folders/common/util/string';
import { AsyncButton } from '../AsyncButton';
import { FormatUploaderInfo } from '../../display/FormatUploaderInfo';
import { Icon } from '../Icon';

function GroupedPartiesRow(props: WizardSidebarSubsetProps & {
  property?: SeoFriendlySlugOptions,
  document?: SeoFriendlySlugOptions,
  timeString?: string,
  timestamp?: number,
  signingSessionId?: string,
  metaPath?: string,
  parties?: SigningParty[],
  formId: string,
  formCode: string,
  initiator: SigningInitiator,
  sessionComplete: boolean,
  salespersons?: AgencySalesperson[],
  propertyData?: MaterialisedPropertyData,
  isSubmitted?: boolean,
  type: PartyCategory,
  hasAuthRep: boolean,
  showColours?: boolean,
  firstPartyInputRequiredPartyId?: string
}) {
  if (!props.parties?.length) {
    return <></>;
  }
  const partyPaths = useMemo(() => {
    if (!props.metaPath) return [];
    if (!props.parties?.length) return [];
    const formInstancePath = props.metaPath;

    return props.parties.map(p => {
      return getPartyDetailPaths(
        p,
        props.propertyData,
        formInstancePath
      );
    });
  }, [props.parties.length, !!props.propertyData, props.metaPath]);
  const { ydoc } = useContext(YjsDocContext);
  const { value: signingOrder, transactionMetaRootKey: metaRootKey } = useLightweightTransaction<SigningSessionOrderItem[]>({
    parentPath: props.metaPath,
    myPath: 'signing.session.signingOrder',
    bindToMetaKey: true
  });
  const signingOrderNode = signingOrder?.find(o => o.type === props.type);

  return <WizardStepPage
    name={props.name}
    label={props.label}
    icon={props.icon}
    headerContent={ydoc &&
    props.signingSessionId &&
    signingOrderNode &&
    signingOrderNode.state !== 'active' &&
    props.parties?.some(p => p.type === SigningPartyType.SignOnline || p.type === SigningPartyType.SignOnlineSms) && !props.firstPartyInputRequiredPartyId
      ? <Button variant={signingOrderNode.auto || signingOrderNode.state === 'inactive'
        ? 'outline-secondary'
        : 'primary'
      } onClick={() => {
        if (!ydoc) return;
        if (!props.signingSessionId) return;

        unblockSigningPartyCategory(ydoc, {
          category: signingOrderNode.type,
          formId: props.formId,
          formCode: props.formCode,
          signingSessionId: props.signingSessionId,
          metaRootKey
        });
      }}>Start {partyCategoryToLabelStrings(signingOrderNode.type, false).singular} signing</Button>
      : <></>}
  >
    <Row>
      <div className='grouped-party-list'>
        {props.parties.map((party, index) => {
          const paths = partyPaths.at(index);
          return (
            <div key={party.id} className={'my-3'}>
              <PartySessionCard
                property={props.property}
                document={props.document}
                signingSessionId={props.signingSessionId}
                timeString={props.timeString}
                timestamp={props.timestamp || 0}
                formId={props.formId}
                formCode={props.formCode}
                paths={{
                  party: `${props.metaPath}.signing.parties.[${party.id}]`,
                  fields: `${props.metaPath}.signing.session.fields`,
                  source: paths?.data?.base || '',
                  sourceEmail: paths?.data?.email || '',
                  sourcePhone: paths?.data?.phone || ''
                }}
                initiator={props.initiator}
                sessionComplete={props.sessionComplete}
                salespersons={props.salespersons}
                partyId={party.id}
                isSubmitted={props.isSubmitted}
                signingOrder={signingOrderNode}
                hasAuthRep={props.hasAuthRep}
                colour={props.showColours ? party.colour : undefined}
                firstPartyInputRequiredPartyId={props.firstPartyInputRequiredPartyId}
              />
            </div>
          );
        })}
      </div>
    </Row>
  </WizardStepPage>;
}

function RenderForSeparateParties(props: {
  property?: SeoFriendlySlugOptions,
  document?: SeoFriendlySlugOptions,
  timeString?: string,
  timestamp?: number,
  signingSessionId?: string,
  metaPath?: string,
  parties?: SigningParty[],
  formId: string,
  formCode: string,
  initiator: SigningInitiator,
  sessionComplete: boolean,
  salespersons?: AgencySalesperson[],
  propertyData?: MaterialisedPropertyData,
  isSubmitted?: boolean,
  hasAuthRep: boolean,
  showColours?: boolean,
  firstPartyInputRequiredPartyId?: string
}) {
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: props.metaPath,
    myPath: 'signing',
    bindToMetaKey: true
  });
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);

  const { sortedParties, partyPaths } = useMemo(() => {
    if (!props.metaPath) return {};
    if (!signing?.parties?.length) return {};

    const sortedParties = getInvolvedSigningParties(signing)
      .sort(byMapperFn(p => p.signingOrderSettings?.order ?? 99999));
    const partyPaths = sortedParties.map(p => getPartyDetailPaths(p, props.propertyData, props.metaPath));
    return {
      sortedParties,
      partyPaths
    };
  }, [signing]);

  if (!sortedParties?.length) return <></>;

  return <WizardStepPage
    name={'Parties'}
    label={'Parties'}
    icon={''}
  >
    {sortedParties.map((party, index) => {
      const paths = partyPaths.at(index);
      return <div key={party.id} className='my-3'>
        <PartySessionCard
          property={props.property}
          document={props.document}
          signingSessionId={props.signingSessionId}
          timeString={props.timeString}
          timestamp={props.timestamp || 0}
          formId={props.formId}
          formCode={props.formCode}
          paths={{
            party: `${props.metaPath}.signing.parties.[${party.id}]`,
            fields: `${props.metaPath}.signing.session.fields`,
            source: paths?.data?.base || '',
            sourceEmail: paths?.data?.email || '',
            sourcePhone: paths?.data?.phone || ''
          }}
          initiator={props.initiator}
          sessionComplete={props.sessionComplete}
          salespersons={props.salespersons}
          partyId={party.id}
          isSubmitted={props.isSubmitted}
          hasAuthRep={props.hasAuthRep}
          colour={props.showColours ? party.colour : undefined}
          firstPartyInputRequiredPartyId={props.firstPartyInputRequiredPartyId}
        />
      </div>;
    })}</WizardStepPage>;
}

export function SigningSession({
  formCode,
  formId,
  ydoc,
  onVoid,
  wizardSectionProps,
  dataRootKey = PropertyRootKey.Data,
  metaRootKey = PropertyRootKey.Meta,
  otherButton,
  partyGroups,
  signingMainProps,
  serveToPurchaserProps,
  showPartyColours,
  hideCompleteIfNoParties
}: {
  formCode: FormCodeUnion,
  formId: string,
  ydoc: Maybe<Y.Doc>
  onVoid: () => void;
  wizardSectionProps: WizardSidebarSubsetProps[],
  dataRootKey: string,
  metaRootKey: string
  otherButton?: JSX.Element,
  partyGroups?: PartyGroup[],
  parties?: SigningParty[],
  signingMainProps?: WizardSidebarSubsetProps,
  serveToPurchaserProps?: WizardSidebarSubsetProps,
  showPartyColours?: boolean,
  hideCompleteIfNoParties?: boolean
}) {
  const navigate = useNavigate();
  const sublineageId = dataRootKey && dataRootKey !== PropertyRootKey.Data ? dataRootKey : undefined;

  const [showVoidConfirm, setShowVoidConfirm] = useState(false);
  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(ydoc, metaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const {
    bindState: metaBindStateMaster
  } = useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta);
  const { data: metaMaster } = metaBindStateMaster<TransactionMetaData>(m => m);
  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, dataRootKey);
  const { instance: fileSync } = useContext(FileSyncContext);
  const {
    bindState: rootDataBindState
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, PropertyRootKey.Data);
  const {
    binder: fileTrackBinder
  } = useImmerYjs<FileTrackDocRoot>(ydoc, PropertyRootKey.FileTrack);
  const propertyData = dataBinder?.get();
  const { data: rootData } = rootDataBindState<MaterialisedPropertyData>(s => s);

  const instancePath = normalisePathToStr(FormUtil.generateFormInstancePath(formCode, formId));
  const instance = FormUtil.getFormState(formCode, formId, meta);
  const needMainDataAgents = (dataRootKey !== PropertyRootKey.Data && !propertyData?.agent);

  const signed = SignedStates.has(instance?.signing?.state || FormSigningState.None);
  const serveForm1ContractSignedDate = signed ? formatTimestamp(instance?.signing?.session?.completedTime, undefined, false) : undefined;

  const metaPath = FormUtil.getFormPath(formCode, formId);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const property: Maybe<SeoFriendlySlugOptions> = propertyData
    ? {
      id: propertyData.id,
      nicetext: generateHeadlineFromMaterialisedData(propertyData)
    }
    : undefined;
  const document: Maybe<SeoFriendlySlugOptions> = instance
    ? {
      id: instance.id,
      nicetext: FormTypes[instance.formCode]?.label
    }
    : undefined;

  const [showHistory, setShowHistory] = useState(false);
  const showHistoryParams = useMemo(() => {
    if (!propertyData?.id) return undefined;
    if (!instance?.signing?.session?.id) return undefined;

    return {
      propertyId: propertyData.id,
      signingSessionId: instance.signing.session.id
    };
  }, [!!propertyData?.id, !!instance?.signing?.session?.id]);

  const firstPartyInputRequiredPartyId = useMemo(() => {
    if (!instance?.signing) return undefined;
    const requiresFirstPartyInput = (instance.signing.customFields || []).some(cf => customFieldMetas[cf.type].firstPartyOnly);
    if (!requiresFirstPartyInput) return undefined;

    const first = getFirstOrderedParty(instance.signing.parties || [], instance.signing);
    if (!first) return undefined;

    const incomplete = (instance.signing.session?.fields || []).some(field => field.partyId === first.id && !fieldIsSigned(field));
    return incomplete
      ? first.id
      : undefined;
  }, [instance]);

  if (!instance?.signing?.parties) {
    return <></>;
  }

  const signingSessionTimeString = getTimeString(instance.signing.session?.timestamp, sessionInfo?.timeZone);

  const family = FormTypes[formCode].formFamily;
  const variationCode = Object.entries(FormTypes).filter(([_,type])=>type.isVariation && type.formFamily === family)[0]?.[0];

  const activeVariation = useMemo(()=>{
    if (instance.signing?.state !== FormSigningState.Signed || !variationCode) {
      return;
    }

    const instances = meta?.formStates?.[family]?.instances;
    return (instances??[]).filter(inst => {
      const type = FormTypes[inst.formCode];
      return type?.isVariation && inst.signing?.state !== FormSigningState.Signed;
    })[0];
  }, [signed, variationCode]);

  const navigateToForm = (instanceId: string, formCode: string) => {
    const niceText = FormTypes[formCode]?.label;
    navigate(`../document/${LinkBuilder.seoFriendlySlug(instanceId, niceText)}`);
  };

  const createForm = (formCode: string) => {
    const newFormPromise = handleNewForm(ydoc, formCode, fileSync, {}, dataRootKey, metaRootKey);
    newFormPromise.then(newForm=>{
      if (!newForm) {
        return;
      }
      navigateToForm(newForm.formId, formCode);
    });
  };

  const [reQueueDone, setRequeueDone] = useState(false);

  const reQueueUploads = () => {
    if (!instance.signing?.session?.file.id) return;
    const fileId = instance.signing?.session?.file.id;
    if (!fileId) return;
    fileTrackBinder?.update(draft=>{
      if (!draft.clientReup2) {
        draft.clientReup2 = {};
      }
      if (!draft.clientReup2[fileId]) {
        draft.clientReup2[fileId] = { ver: 0 };
      }
      draft.clientReup2[fileId].ver++;
      const snapFileId = instance.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;

      const marketingData: SigningMarketingData = {
        landType: typeof propertyData?.landType === 'number' ? propertyData?.landType : LandType.Unknown,
        transactionType: propertyData?.transactionType,
        documentTemplateName: FormTypes[instance?.formCode || '']?.label, // We probably don't need to pass this
        // through here, but it'll do for now
        propertyAddress: propertyData?.saleAddrs?.map(addr => addr?.streetAddr_parts).filter(Predicate.isNotNullish) || []
      };
      draft.clientReup2[fileId].manifest = {
        manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot,
        data: {
          accompanying: { ...(snapFileId ? { propertyDataSnapshot: { fileId: snapFileId } } : {}) },
          formInstance: instance,
          marketingData,
          bucketIngestForceRetry: true
        }
      };

      const propertyId = propertyData?.id;
      if (propertyId) {
        draft.clientReup2[fileId].related = {
          propertyId,
          propertyFile: {
            formCode: instance.formCode,
            formId: instance.id,
            propertyId,
            signingSessionId: instance.signing?.session?.id
          }
        };
      }
    });
    setRequeueDone(true);
  };

  const isSubmitted = FormUtil.instanceIsSubmitted(instance);
  const initiator = instance?.signing?.session?.initiator;
  const hasSignatures = instance?.signing?.parties.some(p => p.signedTimestamp);
  const hasAuthRep = instance?.signing?.parties.some(p => p.source.isAuthRep);
  const salespersons = (needMainDataAgents ? rootData : propertyData)?.agent?.map(agency => agency.salesp || [])?.flat();
  const variationMakesSense = Object.values(FormTypes).some(ft=>ft.isVariation && ft.formFamily===family);
  const orderVersion = getSigningOrderVersion(instance?.signing);

  const shouldServeForm1 = [FormCode.RSC_ContractOfSale, ExtraFormCode.SCV_ContractOfSaleVariation].includes(formCode);
  const form1Instance = FormUtil.getForm1Instances(metaMaster).at(0);
  const form1Path = FormUtil.getFormPath(SubscriptionFormCode.SAF001V2_Form1, form1Instance?.id);

  const signerRecipientSourceFamily = normalisePathToStr(['formStates', FormTypes[formCode].formFamily]);
  const { value: sourceInstances } = useLightweightTransaction<FormInstance[]>({
    parentPath: signerRecipientSourceFamily,
    myPath: 'instances',
    bindToMetaKey: true
  });

  const hideSummary = !(
    hideCompleteIfNoParties
    && instance.signing?.state === FormSigningState.Signed
    && (instance.signing.parties?.length??0 )=== 0
  );

  const { purchasers: latestPurchasers } = FormUtil.extractLatestSigningPurchasersFromInstances(sourceInstances);

  return <>
    {signingMainProps && hideSummary && <WizardStepPage key='summary' {...signingMainProps}>
      <Row>
        <div className='d-flex flex-wrap'>
          <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
            <div>Initiated by: {initiator?.portalUser?.name || initiator?.name}</div>
            {!signed && <div>Signing session began: {getTimeString(instance.signing?.session?.timestamp, sessionInfo?.timeZone, '')}</div>}
            {signed && instance.signing?.session?.completedTime && <div>Signing session completed: {getTimeString(instance.signing?.session?.completedTime, sessionInfo?.timeZone, '')}</div>}
          </div>
          <div className='flex-grow-0 child-spacing-1 mb-2 mt-2'>
            {otherButton}
            {variationMakesSense && signed && activeVariation && <Button variant={'outline-secondary'} onClick={()=>{
              navigateToForm(activeVariation.id, activeVariation.formCode);
            }}>
              Open variation
            </Button>}
            {variationMakesSense && signed && !activeVariation && !instance.subscription?.documentId && <Button variant={'outline-secondary'} onClick={()=>{
              createForm(variationCode);
            }}>
              Create variation
            </Button>}
            {isSubmitted && <Button variant={'primary'} onClick={()=>{
              navigate(LinkBuilder.vendorToSignPath(property, { id: instance.id, nicetext: instance?.signing?.parties?.find(p => p.source.type === SigningPartySourceType.Purchaser)?.snapshot?.name }));
            }}>
              Vendor to sign
            </Button>}
            {!signed && !isSubmitted && <Button key='cancelSigning' variant={'outline-danger'} onClick={() => setShowVoidConfirm(true)

            }>
              Void signing
            </Button>}
            {/* Signed should be set only by the server at a point in time when audit entries have been written out. */}
            {showHistoryParams && instance.signing?.state === FormSigningState.Signed && <Button key='cert' variant={'outline-secondary'} onClick={() => window.open(LinkBuilder.restApi(`/properties/${showHistoryParams.propertyId}/signing-sessions/${showHistoryParams.signingSessionId}/certificate`))}>
              Certificate
            </Button>}
            {showHistoryParams && <Button key="history" variant={'outline-secondary'} onClick={() => setShowHistory(true)}>
              Signing history
            </Button>}
            {sessionInfo?.impersonator?.agentId && <Button key="impersonator-reupload" variant={'warning'} onClick={() => reQueueUploads()} disabled={reQueueDone}>
              Re-Queue Uploads
            </Button>}
          </div>
        </div>
      </Row>
    </WizardStepPage>}
    {/*<pre>{JSON.stringify(getSigningOrderDump(instance?.signing))}</pre>*/}
    {initiator && orderVersion === SigningOrderVersion.Grouped && partyGroups && partyGroups.map((pg, index) => (<GroupedPartiesRow
      key={`pg-${index}`}
      name={pg.label}
      label={pg.label}
      icon={pg.icon}
      property={property}
      document={document}
      timeString={signingSessionTimeString}
      timestamp={instance?.signing?.session?.timestamp}
      signingSessionId={instance?.signing?.session?.id}
      metaPath={metaPath}
      parties={pg.parties}
      formId={formId}
      formCode={formCode}
      initiator={initiator}
      sessionComplete={signed}
      salespersons={salespersons}
      propertyData={propertyData}
      isSubmitted={isSubmitted}
      type={pg.type}
      hasAuthRep={hasAuthRep}
      showColours={showPartyColours}
      firstPartyInputRequiredPartyId={firstPartyInputRequiredPartyId}
    />))}
    {initiator && orderVersion === SigningOrderVersion.Flat && <RenderForSeparateParties
      property={property}
      document={document}
      timeString={signingSessionTimeString}
      timestamp={instance?.signing?.session?.timestamp}
      signingSessionId={instance?.signing?.session?.id}
      metaPath={metaPath}
      formId={formId}
      formCode={formCode}
      initiator={initiator}
      sessionComplete={signed}
      salespersons={salespersons}
      propertyData={propertyData}
      isSubmitted={isSubmitted}
      hasAuthRep={hasAuthRep}
      showColours={showPartyColours}
      firstPartyInputRequiredPartyId={firstPartyInputRequiredPartyId}
    />}
    {!SignedStates.has(instance?.signing?.state) && instance.signing.parties.length > 0 && <WizardStepPage
      key='signing-notification'
      icon='email'
      label='Signing notifications'
      name='signing-notification'
      independentCollapsible={true}
    >
      <div>Notify parties about progress and completion signing events for this document:</div>
      <div className='mt-2'>
        {instance.signing.parties.filter(p => p.type !== SigningPartyType.SignWet)?.map(party => {
          const isInitiator = instance.signing?.sessionInitiator?.id === party.snapshot?.linkedSalespersonId;
          const email = getProxyEmail(party);
          const missingEmail = !email;
          const partyName = `${getProxyWithOriginalName(party)}${isInitiator ? ' (Initiator)' : missingEmail ? ' (No email address)' : ''}`;
          return <WrField.BoolCheck
            disabled={missingEmail || isInitiator}
            overrideChecked={isInitiator ? true : missingEmail ? false : undefined}
            nullishIsFalse={true}
            bindToMetaKey={true}
            parentPath={normalisePathToStr(mergePaths(instancePath, 'signing.parties', `[${party.id}]`))}
            name={`[${party.id}].notificationBlock`}
            myPath='notificationBlock'
            inverter={true}
            label={partyName}
          />;
        })}
      </div>
      <div className='mt-2'>
        It is your obligation to ensure that all parties receive a copy of the completed document.
      </div>
    </WizardStepPage>}
    <WizardStepPage
      key='distribution-status'
      name='distribution-status'
      label='Distribution'
      icon='send'
    >
      <SigningSessionDistribution
        instance={instance}
        instancePath={instancePath}
        parties={instance.signing.parties}
        propertyData={propertyData}
      />
    </WizardStepPage>
    {ydoc && propertyData?.id && serveToPurchaserProps && (
      shouldServeForm1
        ? <SetupNetStateWritingYjsDocContext
          ydoc={ydoc}
          docName={propertyData?.id}
          transactionRootKey={PropertyRootKey.Data}
          transactionMetaRootKey={PropertyRootKey.Meta}
        >
          <ServeToPurchaserSection
            key='serve_purchaser'
            formId={form1Instance?.id}
            signerSourceFormCode={formCode}
            formCode={form1Instance?.formCode as FormCodeUnion}
            propertyId={propertyData?.id}
            metaPath={form1Path}
            contractMode={true}
            contractInstancePath={instancePath}
            contractSignedDate={serveForm1ContractSignedDate}
            contractYdocMetaKey={metaRootKey}
            latestPurchasers={latestPurchasers}
            originatingForm={{ id: formId, code: formCode, sublineageId, source: 'signing-session'  }}
            {...serveToPurchaserProps}
          />
        </SetupNetStateWritingYjsDocContext>
        : <ServeToPurchaserSection
          key='serve_purchaser'
          formId={formId}
          signerSourceFormCode={formCode}
          formCode={formCode}
          propertyId={propertyData?.id}
          metaPath={metaPath}
          originatingForm={{ id: formId, code: formCode, sublineageId, source: 'signing-session'  }}
          {...serveToPurchaserProps}
        />
    )}
    {showHistoryParams && showHistory && <HistoryModal key={'show-history-modal'} propertyId={showHistoryParams.propertyId} signingSessionId={showHistoryParams.signingSessionId} onClose={() => setShowHistory(false)} />}
    <VoidSigningModal
      show={showVoidConfirm}
      onHide={()=>setShowVoidConfirm(false)}
      formInstance={instance}
      hasSignatures={hasSignatures}
      onConfirm={onVoid}
    />
    {instance?.upload?.v === 2 && <UploadedDocumentsSection timeZone={sessionInfo?.timeZone} />}
  </>;
}

function UploadedDocumentsSection({ timeZone }: {timeZone?: string }) {
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: form } = useTransactionField<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode as FormCodeUnion, formId),
    bindToMetaKey: true
  });
  const { value: data } = useTransactionField<MaterialisedPropertyData>({ myPath: '' });
  const worker = usePdfWorker();

  const upload = getUploadAsV2(form?.upload, form?.created);
  const files = upload?.files ?? [];
  const handleDownload = async () => {
    const fileToSplit = getCompletedFiles(form?.signing?.session).at(0) || form?.signing?.session?.file;
    if (!fileToSplit) return;

    const pdf = await FileStorage.read(fileToSplit.id);
    if (!pdf?.data) return;

    const name = stripExtension(upload?.name) || generateHeadlineFromMaterialisedData(data);
    if (form?.signing?.session?.splits?.length) {
      const zip = await worker.split({
        pdf: pdf.data,
        splits: form.signing.session.splits
      });
      const fileName = StringUtil.sanitiseFileName(`${name}.zip`);
      if (typeof zip === 'string') {
        downloadObjectUrl(zip, fileName);
      } else {
        downloadObjectUrl(URL.createObjectURL(zip), fileName);
      }
    } else {
      downloadObjectUrl(URL.createObjectURL(pdf.data), `${name}.pdf`);
    }
  };

  const handleDownloadSingle = async (item: FormInstanceUploadFileItem, index: number) => {
    if (!form?.signing?.session?.splits?.length) return;

    const fileName = StringUtil.sanitiseFileName(`${stripExtension(item.name)}.pdf`);
    const fileToSplit = getCompletedFiles(form?.signing?.session).at(0) || form?.signing?.session?.file;
    if (!fileToSplit) return;

    const pdf = await FileStorage.read(fileToSplit.id);
    if (!pdf?.data) return;

    const idIndex = form.signing.session.splits.findIndex(s => s.sourceId === item.id);
    const splitIndex = idIndex >= 0 ? idIndex : index;
    const split = form.signing.session.splits[splitIndex];
    if (!split) return;

    const next = form.signing.session.splits.at(splitIndex + 1);
    const result = await worker.extractRange({ pdf: pdf.data, start: split.page, exclusiveEnd: next?.page });

    if (typeof result === 'string') {
      downloadObjectUrl(result, fileName);
    } else {
      downloadObjectUrl(URL.createObjectURL(result), fileName);
    }
  };

  return <>
    <Card className='card wiz-card bg-white mx-0 mx-sm-2 mx-md-4 shadow'>
      <Card.Header className={'bg-white accordion-card-header card-header d-flex flex-row justify-content-between'}>
        <div className='title'>
          <span className='fs-3'>Documents</span>
        </div>
        <div>
          <AsyncButton
            variant='outline-secondary'
            title='Download all the files as a zip package'
            onClick={handleDownload}>Download All</AsyncButton>
        </div>
      </Card.Header>
      <Container className='p-3' style={{ display: 'grid', gridTemplateColumns: '2fr 3fr min-content' }}>

        {files.map((file, index) => {
          return <Fragment key={index}>
            <div>{file.name}</div>
            <div>
              <FormatUploaderInfo
                name={file?.uploader?.name}
                size={file?.size}
                timestamp={file?.created}
                timeZone={timeZone}
              />
            </div>
            <div className='ps-3 pe-2 py-2'>
              <AsyncButton
                variant='link'
                title='Download File'
                className='px-0 py-0 border-0 link-secondary text-decoration-none'
                onClick={() => handleDownloadSingle(file, index)}
              >
                <Icon name={'download'}/>
              </AsyncButton>
            </div>
          </Fragment>;
        })}
      </Container>
    </Card>
  </>;
}
